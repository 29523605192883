@import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@300&display=swap');
@import url('https://cdn.jsdelivr.net/npm/lightgallery@2.0.0-beta.4/css/lightgallery.css');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply bg-orange-50 font-serif;
  }
  a:hover {
    @apply underline;
  }
  button:hover {
    @apply cursor-pointer;
  }
  button:disabled {
    @apply bg-gray-200 text-gray-400 border-none cursor-default;
  }
  h1 {
    @apply text-3xl font-bold mb-6;
  }
  h2 {
    @apply text-xl mb-2;
  }
  h3 {
    @apply text-lg mb-2;
  }
  p {
    @apply mb-6 text-base;
  }
  ul, ol {
    @apply mb-4 pl-6;
  }
  ul li {
    @apply list-disc mb-2;
  }
  ol li {
    @apply list-decimal mb-2;
  }
}

@layer components {
  .cite-left {
    @apply w-full md:w-[40%] border-black border-t border-b py-4 mb-2 float-left mr-6;
  }
  .cite-right {
    @apply w-full md:w-[40%] border-black border-t border-b py-4 mb-2 float-right ml-6;
  }
  .btn-large {
    @apply inline-block text-center bg-orange-800 text-white px-20 py-4 rounded-full no-underline hover:bg-black;
  }
  .btn-primary {
    @apply px-6 py-2 text-white bg-black hover:bg-orange-800;
  }
  .alert {
    @apply py-2 text-xs my-2;
  }
  .alert-success {
    @apply text-green-600;
  }
  .alert-warning {
    @apply text-yellow-600;
  }
  .alert-error {
    @apply text-red-600;
  }
  #page {
    @apply bg-orange-50 font-serif;
  }
  .ad-label {
    @apply text-black text-xs absolute -top-5 right-0 font-bold;
  }
  .paging {
    @apply flex list-none mt-2 mb-4 justify-center;
  }
  .page-item {
    @apply list-none mx-2 text-sm text-center;
  }
  .page-link {
    @apply p-1.5 w-6 h-6 leading-3 text-black cursor-pointer inline-block;
  }
  .page-item.active .page-link {
    @apply bg-orange-800 text-white rounded-full;
  }
  .author-description {
    @apply mb-6 bg-white p-4 rounded-2xl;
  }
  .author-description p {
    @apply text-sm;
  }
}

app-article-detail a { text-decoration: underline; }
app-article-detail a:hover { text-decoration: none; }
.lg-sub-html { background: #000; }

app-sklik,
app-ssp,
app-adsense {
  width: 100%;
  display: block;
}

#branding {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  margin: 0 auto;
}

#branding.adFull ~ #page {
  position: relative;
  z-index: 1;
  top: 210px;
  max-width: 1060px;
  margin: 0 auto;
  contain: paint;
}
